import React, {useRef, forwardRef, useEffect} from "react";
import {useHistory} from 'react-router-dom';
import SVG from "react-inlinesvg";
import main from "./assets/nav_landscape.svg";
import "./_navigation.scss";
import gsap from "gsap";
import useStore, {api} from "../../store/store";

const Main = forwardRef((props, ref) => (
    <SVG innerRef={ref} title="MyLogo" {...props} />
));

function Navigation() {
    const history = useHistory();
    const svgRef = useRef();
    const actions = useStore(state => state.actions);
    const pageIndex = useStore(state => state.pageIndex);
    const openNav = useStore(state => state.openNav);
    const pageButtons = useRef([])
    useEffect(()=> {
        if (!pageButtons.current || !pageButtons.current.length) return;
        console.log('', pageIndex);
        switch (pageIndex) {
            case 1:
                hilite(1,0,0,0,0)
                break;
            case 2:
                hilite(0,0.5,0,0,0)
                break;
            case 3:
                hilite(0,0.5,0,0,0)
                break;
            case 4:
                hilite(0,1,0,0,0)
                break;
            case 5:
                hilite(0,0,0.5,0,0)
                break;
            case 6:
                hilite(0,0,0.5,0,0)
                break;
            case 7:
                hilite(0,0,0,0.5,0)
                break;
            case 8:
                hilite(0,0,0,1,0)
                break;
            case 9:
                hilite(0,0,0,0,1)
                break;
            case 10:
                hilite(0,0,0,0,0)
                break;
            default:
                hilite(0,0,0,0,0)
        }
    }, [pageIndex])

    function hilite(b1,b2,b3,b4,b5) {
        getButton(0, b1)
        getButton(1, b2)
        getButton(2, b3)
        getButton(3, b4)
        getButton(4, b5)
    }

    function getButton(v, val) {
        switch (val) {
            case 0:
                getHalf(v).style.opacity = 0;
                getFull(v).style.opacity = 1;
                getFull(v).style.fill = "#004892"
                return
            case 0.5:
                getHalf(v).style.opacity = 1;
                getFull(v).style.opacity = 1;
                getFull(v).style.fill = "#004892"
                return
            case 1:
                getHalf(v).style.opacity = 0;
                getFull(v).style.opacity = 1;
                getFull(v).style.fill = "#DD7E29"
                return

        }
    }

    function getHalf(i) {
        return  pageButtons.current[i].querySelector(`[inkscape\\:label=half]`)
    }
    function getFull(i) {
        return  pageButtons.current[i].querySelector(`[inkscape\\:label=bg]`)
    }

    function doAnim() {

        const bt_home = svgRef.current.querySelector("#BT_HOME");
        const bt_menu = svgRef.current.querySelector("#BT_MENU");
        const bt_left = svgRef.current.querySelector("#BT_LEFT");
        const bt_right = svgRef.current.querySelector("#BT_RIGHT");
        const bt_one = svgRef.current.querySelector("#BT_ONE");
        const bt_two = svgRef.current.querySelector("#BT_TWO");
        const bt_three = svgRef.current.querySelector("#BT_THREE");
        const bt_four = svgRef.current.querySelector("#BT_FOUR");
        const bt_five = svgRef.current.querySelector("#BT_FIVE");

        pageButtons.current = [
            bt_one, bt_two, bt_three, bt_four, bt_five
        ]

        bt_home.addEventListener("click", ()=> {
            console.log('click home');
            actions.setPageIndex(0);
        })
        bt_menu.addEventListener("click", ()=> {
            console.log('click menu');
            actions.setOpenNav( !api.getState().openNav )
        })
        /**
         * go left
         */
        bt_left.addEventListener("click", ()=> {
            actions.setSwipeIndex(true)
        })
        /**
         * go right
         */
        bt_right.addEventListener("click", ()=> {
            actions.setSwipeIndex(false)
        })
        /**
         * main nav buttons
         */
        bt_one.addEventListener("click", ()=> {
            actions.setPageIndex(1);
        })
        bt_two.addEventListener("click", ()=> {
            actions.setPageIndex(2);
        })
        bt_three.addEventListener("click", ()=> {
            actions.setPageIndex(5);
        })
        bt_four.addEventListener("click", ()=> {
            actions.setPageIndex(7);
        })
        bt_five.addEventListener("click", ()=> {
            actions.setPageIndex(9);
        })
        /**
         * hide highlights
         */
        pageButtons.current.forEach(b => {
            b.querySelector(`[inkscape\\:label=half]`).style.opacity = 0;
        })
    }

    return (
        <div className="pagewrapper">
            <Main ref={svgRef} src={main} onLoad={doAnim}/>
            <div className="navbg" />
        </div>
    )
}
export default Navigation
