import React, {useRef} from "react";
import img from "../../assets/img/page_15.png"

function Page15({index, show}) {
    const all = useRef();

    return (
        <div ref={all}>
            <img src={img} />
        </div>
    )
}

export default Page15
