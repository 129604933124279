import {useKeyPress, useToggle} from "../utils/hooks";

const screenshotStyle = {
    "width": "100%",
    "height": "100%",
    "top": "0",
    "position": "absolute",
    "backgroundSize": "contain",
    "backgroundPosition": "center",
    "backgroundRepeat": "no-repeat",
    "zIndex": "2",
    "opacity": "1",
    "pointerEvents": "none"
}

function ScreenShotCover() {
    const [toggle, setToggle] = useToggle();
    useKeyPress('Shift', ()=> {
        setToggle()
    })

    return (
        <div style={screenshotStyle}>
            <div className="full" style={{display: toggle ? "block" : "none"}}>
            </div>
        </div>
    )
}
export default ScreenShotCover;
