import React, {useEffect, useRef} from "react";
import img from "../../assets/img/page_12.png";
import img2 from "../../assets/img/page_13.png";
import "./_p10.scss";
import create from "zustand";
import gsap from "gsap";

const [tabStore] = create((set, get) => {
    return {
        selected: 0,
        actions: {
            setSelected: (v) => {
                set(state => ({selected: v}));
            }
        }
    }
});


function Page10({index, show}) {
    const all = useRef();
    const r1 = useRef();
    const r2 = useRef();
    const selected = tabStore(state => state.selected);
    const actions = tabStore(state => state.actions);
    const allRef = useRef([r1,r2])
    useEffect(()=> {
        actions.setSelected(0)
        return ()=> {
            actions.setSelected(0)
        }
    }, [])
    useEffect(()=> {
        if (!selected) return
        reset();
        gsap.to(allRef.current[selected-1].current, {duration: 0, alpha: 1})
    }, [selected])

    function reset() {
        allRef.current.forEach(r => {
            r.current.style.opacity = 0;
        })
    }

    return (
        <div ref={all}>
            <img src={img} ref={r1} className="tabimage tabimage--1"/>
            <img src={img2} ref={r2} className="tabimage"/>
            <div className="tabclick tabclick--1"  onClick={()=> actions.setSelected(1)}/>
            <div className="tabclick tabclick--2"  onClick={()=> actions.setSelected(2)}/>
        </div>
    )
}

export default Page10
