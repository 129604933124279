import './App.scss';
import {Switch, Route, useLocation, useHistory} from 'react-router-dom';
import {useTransition, animated} from 'react-spring';
import Navigation from "./components/navigation/Navigation";
import PageWrapper from "./components/pagewrapper/PageWrapper";
import {useEffect, useRef} from "react";
import useStore, {pages} from "./store/store";
import Swiping from "./components/swiping/Swiping";
import ScreenShotCover from "./debug/ScreenShotCover";
import {useAnimationFrame} from "./utils/hooks";
import gsap from "gsap";
import Page2 from "./pages/Page2/Page2";

function App() {
    const pageIndex = useStore(state => state.pageIndex)
    const savedPageIndex = useRef(true);
    const direction = useRef(true);
    const location = useLocation();
    const history = useHistory();
    const slideAmount = 160;

    const getTransitionEnter = () => {
        let val_horizontal = direction.current ? slideAmount : -slideAmount;
        return `${val_horizontal}px, 0, 0`
    };
    const getTransitionLeave = () => {
        let val_horizontal = direction.current ? -slideAmount : slideAmount
        return `${val_horizontal}px, 0, 0`
    }

    const transitions = useTransition(location, (location) => location.pathname, {
        config: {mass: 0.5, tension: 550, friction: 60},
        from: {
            opacity: 0,
            transform: `translate3d(${getTransitionEnter()})`,
        },
        enter: {opacity: 1, transform: `translate3d(0, 0, 0)`},
        leave: {
            opacity: 0,
            transform: `translate3d(${getTransitionLeave()})`,
        },
        trail: 0,
    });

    /**
     * Handle page index change
     * set direction of transition
     */
    useEffect(() => {
        if (pageIndex > savedPageIndex.current) {
            direction.current = true;
        } else {
            direction.current = false;
        }
        savedPageIndex.current = pageIndex;
        history.push(pages[pageIndex].path)
    }, [pageIndex, history])

    const pageRef = useRef();
    useAnimationFrame(() => {
        const scale = (window.innerHeight / 2048)
        gsap.set(pageRef.current, {scaleX: scale * 1, scaleY: scale});
    })

    return (
        <div className="App">
            <div className="centercontainer">
                <div className="scalecontainer" ref={pageRef}>
                    <Swiping/>
                    <Page2/>
                    <Navigation/>
                    {transitions.map(({item, props, key}) => (
                        <animated.div className="wrapper" key={key} style={props}>
                            <Switch location={item}>
                                {pages.map((page, idx) => (
                                    <Route key={idx} path={page.path} exact render={() => (
                                        <PageWrapper refs={page.refs} {...props} index={idx}>
                                            {page.component}
                                        </PageWrapper>
                                    )}/>
                                ))}
                            </Switch>
                        </animated.div>
                    ))}

                    <ScreenShotCover/>
                    <div className="overlay"/>
                </div>
            </div>
        </div>
    );
}

export default App;
