import React, {useEffect, useRef} from "react";
import img from "../../assets/img/page_9.png"
import po1 from "../../assets/img/page_10.png";
import create from "zustand";
import gsap from "gsap";
import useStore from "../../store/store";

const [toggleStorep9] = create((set, get) => {
    return {
        selected: 0,
        actions: {
            setSelected: (v) => {
                set(state => ({selected: v}));
            }
        }
    }
});

function Page9({index, show}) {
    const all = useRef();
    const r1 = useRef();
    const actions = toggleStorep9(state => state.actions);
    const selected = toggleStorep9(state => state.selected);
    const pageIndex = useStore(state => state.pageIndex);
    const allRef = useRef([r1])

    useEffect(()=> {
        actions.setSelected(null)
        return ()=> {
            actions.setSelected(null)
        }
    }, [])
    useEffect(()=> {
        if (!selected) return
        reset();
        gsap.to(allRef.current[selected-1].current, {duration: 0.3, alpha: 1})
    }, [selected])

    useEffect(()=> {
        if (pageIndex !== 4) {
            reset()
        }
    }, [pageIndex])

    function reset() {
        allRef.current.forEach(r => {
            gsap.killTweensOf(r.current)
            r.current.style.opacity = 0;
        })
    }

    return (
        <div ref={all}>
            <img src={img} />
            <img src={po1} className="toggleimg toggleimg--1" ref={r1}/>
            <div className="toggleclick toggleclick--page9" onClick={()=> actions.setSelected(1)}/>
        </div>
    )
}

export default Page9
