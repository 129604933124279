import React, {Children, cloneElement, useEffect, useRef, useState} from "react"
import "./_pagewrapper.scss"
import useStore from "../../store/store";
import gsap from "gsap";
import {useAnimationFrame} from "../../utils/hooks";

function PageWrapper({children, page, index}) {
    const [show, setShow] = useState();

    const childrenWithExtraProp = Children.map(children, child =>
        cloneElement(child, { index: index, show: show })
    );

    const actions = useStore(state => state.actions);
    useEffect(()=> {
        actions.setPageIndex(index)
        setShow(true);
    }, [index, actions])



    return (
        <div className="pagewrapper">
            {childrenWithExtraProp}
        </div>
    )
}
export default PageWrapper
