import create from 'zustand';
import Page1 from "../pages/Page1/Page1";
import Page3 from "../pages/Page3/Page3";
import Page4 from "../pages/Page4/Page4";
import Page8 from "../pages/Page8/Page8";
import Page9 from "../pages/Page9/Page9";
import Page10 from "../pages/Page10/Page10";
import Page12 from "../pages/Page12/Page12";
import Page13 from "../pages/Page13/Page13";
import Page21 from "../pages/Page21/Page21";
import Page11 from "../pages/Page11/Page11";
import Page14 from "../pages/Page14/Page14";
import Page15 from "../pages/Page15/Page15";
import Page24 from "../pages/Page24/Page24";

export const pages = [
    {
        path: "/",
        component: <Page1/>
    },
    {
        path: "/page3",
        component: <Page3/>
    },
    {
        path: "/page4",
        component: <Page4/>
    },
    {
        path: "/page8",
        component: <Page8/>
    },
    {
        path: "/page9",
        component: <Page9/>
    },
    {
        path: "/page11",
        component: <Page11/>
    },
    {
        path: "/page12",
        component: <Page10/>
    },
    {
        path: "/page14",
        component: <Page14/>
    },
    {
        path: "/page15",
        component: <Page15/>
    },
    {
        path: "/page21",
        component: <Page13/>
    },
    {
        path: "/page24",
        component: <Page24/>
    }
]
const [useStore, api] = create((set, get) => {
    return {
        pageIndex: 0,
        swipeDirection: 0,
        openNav: false,
        actions: {
            setPageIndex: (v) => {
                set(state => ({pageIndex: v}));
                set(state => ({openNav: false}));
            },
            setOpenNav: (v) => {
                // console.log('open', v);
                set(state => ({openNav: v}));
            },
            setSwipeIndex: (v) => {
                // update index
                let current = get().pageIndex;
                let targ;
                if (v) {
                    targ = --current;
                } else {
                    targ = ++current;
                }
                if (targ < 0 || targ >= pages.length) {
                    console.log('limit');
                } else {
                    set(state => ({pageIndex: targ}));
                }
            },

        }
    }
});
export default useStore;
export {api}
