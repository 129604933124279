import React, {useEffect, useRef} from "react";
import img from "../../assets/img/page_16.png"
import "./_p13.scss"
import create from "zustand";
import gsap from "gsap";

const [p13Store] = create((set, get) => {
    return {
        selected: 0,
        actions: {
            setSelected: (v) => {
                set(state => ({selected: v}));
            }
        }
    }
});

function Page13({index, show}) {
    const all = useRef();
    const r1 = useRef();
    const r2 = useRef();
    const r3 = useRef();
    const r4 = useRef();
    const r5 = useRef();
    const r6 = useRef();
    const r7 = useRef();
    const allRef = useRef([r1, r2, r3, r4, r5, r6, r7])
    const selected = p13Store(state => state.selected);
    const actions = p13Store(state => state.actions);

    useEffect(() => {
        actions.setSelected(0)
        return () => {
            actions.setSelected(0)
        }
    }, [])

    useEffect(() => {
        if (!selected) return
        reset();
        gsap.to(allRef.current[selected - 1].current, {duration: 0.5, alpha: 1})
        gsap.set(allRef.current[selected - 1].current, {y: 50})
        gsap.to(allRef.current[selected - 1].current, {duration: 0.4, y: 0, ease: 'power2.out'})
    }, [selected])

    function reset() {
        allRef.current.forEach(r => {
            gsap.killTweensOf(r.current)
            r.current.style.opacity = 0;
        })
    }


    return (
        <div ref={all}>
            <img src={img}/>
            <div className="btclicks">
                <div className="btclick btclick--1" onClick={() => actions.setSelected(1)}/>
                <div className="btclick btclick--2" onClick={() => actions.setSelected(2)}/>
                <div className="btclick btclick--3" onClick={() => actions.setSelected(3)}/>
                <div className="btclick btclick--4" onClick={() => actions.setSelected(4)}/>
                <div className="btclick btclick--5" onClick={() => actions.setSelected(5)}/>
                <div className="btclick btclick--6" onClick={() => actions.setSelected(6)}/>
                <div className="btclick btclick--7" onClick={() => actions.setSelected(7)}/>

                <div className="btlabels">
                    <div className="p13label p13label--1" ref={r1}>
                        DIAGNOSIS<br/>& PLANNING
                    </div>
                    <div className="p13label p13label--2" ref={r2}>
                        AUGMENTATION
                    </div>
                    <div className="p13label p13label--3" ref={r3}>
                        IMPLANT<br/>PLACEMENT
                    </div>
                    <div className="p13label p13label--4" ref={r4}>
                        TEMPORIZATION
                    </div>
                    <div className="p13label p13label--5" ref={r5}>
                        FINAL<br/>IMPRESSION
                    </div>
                    <div className="p13label p13label--6" ref={r6}>
                        FINAL<br/>RESTORATION
                    </div>
                    <div className="p13label p13label--7" ref={r7}>
                        MAINTENANCE
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Page13
