import React from "react";
import "./_swiping.scss";
import {useDrag} from 'react-use-gesture';
import useStore from "../../store/store";


function Swiping() {
    const actions = useStore(state => state.actions);
    const openNav = useStore(state => state.openNav);
    const H_THRESHOLD = 1.5;
    const bind = useDrag(({last, vxvy: [vx, vy]}) => {

        if (last) {
            // getting the swipe direction
            if (Math.abs(vy) < Math.abs(vx)) {
                if (Math.abs(vx) > H_THRESHOLD) {
                   const left = vx > 0;
                   if (!openNav) {
                       actions.setSwipeIndex(left)
                   }
                }
            } else {

            }
        }
    })
    return (
        <div className="swipe" {...bind()} />
    )
}
export default Swiping;
