import React, {useEffect, useRef} from "react";
import gsap from "gsap";
import img from "../../assets/img/page_4.png"
import po1 from "../../assets/img/page_5.png"
import po2 from "../../assets/img/page_6.png"
import po3 from "../../assets/img/page_7.png"
import "./_p4.scss";
import create from "zustand";
import {pages} from "../../store/store";

const [toggleStore] = create((set, get) => {
    return {
        selected: 0,
        actions: {
            setSelected: (v) => {
                set(state => ({selected: v}));
            }
        }
    }
});


function Page4({index, show}) {
    const all = useRef();
    const r1 = useRef();
    const r2 = useRef();
    const r3 = useRef();
    const selected = toggleStore(state => state.selected);
    const actions = toggleStore(state => state.actions);
    const allRef = useRef([r1,r2,r3])
    useEffect(()=> {
        actions.setSelected(null)
        return ()=> {
            actions.setSelected(null)
        }
    }, [])
    useEffect(()=> {
        if (!selected) return
        reset();
        gsap.to(allRef.current[selected-1].current, {duration: 0.3, alpha: 1})
    }, [selected])

    function reset() {
        allRef.current.forEach(r => {
            gsap.killTweensOf(r.current)
            r.current.style.opacity = 0;
        })
    }

    return (
        <div ref={all}>
            <img src={img} />
            <img src={po1} className="toggleimg toggleimg--1" ref={r1}/>
            <img src={po2} className="toggleimg toggleimg--2" ref={r2}/>
            <img src={po3} className="toggleimg toggleimg--3" ref={r3}/>

            <div className="toggleclick toggleclick--1" onClick={()=> actions.setSelected(1)}/>
            <div className="toggleclick toggleclick--2" onClick={()=> actions.setSelected(2)}/>
            <div className="toggleclick toggleclick--3" onClick={()=> actions.setSelected(3)}/>

        </div>
    )
}

export default Page4
