import React, {useEffect, useRef, useState} from 'react';


export const useAnimationFrame = callback => {
    // Use useRef for mutable variables that we want to persist
    // without triggering a re-render on their change
    const requestRef = React.useRef();
    const previousTimeRef = React.useRef();

    const animate = time => {
        if (previousTimeRef.current !== undefined) {
            const deltaTime = time - previousTimeRef.current;
            callback(deltaTime)
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    }

    React.useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []); // Make sure the effect runs only once
}


// https://www.joshwcomeau.com/snippets/react-hooks/use-toggle/
export function useToggle(initialValue = false) {
    const [value, setValue] = React.useState(initialValue);
    const toggle = React.useCallback(() => {
        setValue(v => !v);
    }, []);
    return [value, toggle];
}
// https://gist.github.com/gragland/b61b8f46114edbcf2a9e4bd5eb9f47f5#gistcomment-3577005
export function useKeyPress(keys, onPress) {
    keys = keys.split(' ').map((key) => key.toLowerCase())
    const isSingleKey = keys.length === 1
    const pressedKeys = useRef([])

    const keyIsRequested = (key) => {
        key = key.toLowerCase()
        return keys.includes(key)
    }

    const addPressedKey = (key) => {
        key = key.toLowerCase()
        const update = pressedKeys.current.slice()
        update.push(key)
        pressedKeys.current = update
    }

    const removePressedKey = (key) => {
        key = key.toLowerCase()
        let update = pressedKeys.current.slice()
        const index = update.findIndex((sKey) => sKey === key)
        update = update.slice(0, index)
        pressedKeys.current = update
    }

    const downHandler = ({ key }) => {
        const isKeyRequested = keyIsRequested(key)
        if (isKeyRequested) {
            addPressedKey(key)
        }
    }

    const upHandler = ({ key }) => {
        const isKeyRequested = keyIsRequested(key)
        if (isKeyRequested) {
            if (isSingleKey) {
                pressedKeys.current = []
                onPress()
            } else {
                const containsAll = keys.every((i) => pressedKeys.current.includes(i))
                removePressedKey(key)
                if (containsAll) {
                    onPress()
                }
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler)
        window.addEventListener('keyup', upHandler)
        return () => {
            window.removeEventListener('keydown', downHandler)
            window.removeEventListener('keyup', upHandler)
        }
    }, [])
}
