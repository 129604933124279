import React, {useEffect, useRef} from "react";
import gsap from "gsap";
import img from "../../assets/img/page_2.png"
import "./_p2.scss";
import useStore from "../../store/store";

function Page2({index, show}) {
    const all = useRef();
    const bt_close = useRef();
    const openNav = useStore(state => state.openNav);
    const actions = useStore(state => state.actions);



    useEffect(()=> {
        if (openNav) {
            gsap.set(all.current, {y: 50})
            gsap.to(all.current, {duration: 0.3, y: 0, autoAlpha: 1, ease: "power2.out"})
        } else {
            gsap.to(all.current, {duration: 0.3, y: 0, autoAlpha: 0})
        }
    }, [openNav])

    return (
        <div className="pagewrapper p2" ref={all}>
            <div>
                <img src={img}/>
                <div className="menubt menubt--close" ref={bt_close} onClick={()=> {
                    actions.setOpenNav(false)
                }}/>
                <div className="menubt menubt--1" onClick={()=> {
                    actions.setPageIndex(1);
                }}/>
                <div className="menubt menubt--2" onClick={()=> {
                    actions.setPageIndex(2);
                }}/>
                <div className="menubt menubt--3" onClick={()=> {
                    actions.setPageIndex(5);
                }}/>
                <div className="menubt menubt--4" onClick={()=> {
                    actions.setPageIndex(7);
                }}/>
                <div className="menubt menubt--5" onClick={()=> {
                    actions.setPageIndex(9);
                }}/>
            </div>
        </div>
    )
}

export default Page2
